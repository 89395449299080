<template>
  <div>
    <v-card>
      <v-card-title>Tabla de notas : {{ grupo_name }}</v-card-title>
      <v-container>
        <v-row justify="space-around">
          <v-col align-self="center" cols="10" md="3">
            <v-select
              return-object
              :items="items_docente"
              item-text="title"
              item-value="id_grupo"
              label="Seleciona el grupo"
              chips
              v-model="id_grupo_selected"
              @change="get_cursos()"
            ></v-select>
          </v-col>
          <v-col align-self="center" cols="10" md="3">
            <v-select
              return-object
              :items="cursos"
              item-text="nombre_curso"
              item-value="id"
              label="Seleciona el curso"
              chips
              v-model="curso_selected"
            ></v-select>
          </v-col>
          <v-col align-self="center" cols="10" md="3">
            <v-btn
              color="primary"
              :disabled="!ver_alumno_status_btn"
              block
              outlined
              @click="actualizar_tabla()"
            >
              Ver Notas
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="loaded">
        <v-row>
          <v-col>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>#</th>
                    <th class="text-left">
                      Apellidos y Nombres
                    </th>
                    <th
                      v-for="item in periodos_academicos"
                      :key="item.id"
                      class="text-left"
                    >
                      {{ item.denominacion }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, ind) in students_notas"
                    :key="item.full_name"
                  >
                    <td>{{ ind + 1 }}</td>
                    <td width="400px">{{ item.full_name }}</td>
                    <td v-for="(i, index) in item.notas" :key="i.id_historial">
                      <input
                        min="0"
                        max="20"
                        step="1"
                        :disabled="!arr_status_periodo_academico[index]"
                        type="number"
                        v-model.number="i.calificacion"
                        @change="ejecutar()"
                        class="input-style"
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="10" md="8">
            <v-btn
              :disabled="!status_button_save"
              color="primary"
              block
              @click="post_notas()"
              >Guardar Notas</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
      <div
        v-else-if="
          !loaded && id_grupo_selected && curso_selected && view_loaded
        "
      >
        <v-progress-linear
          color="deep-purple accent-4"
          indeterminate
          rounded
          height="4"
        ></v-progress-linear>
      </div>
      <v-snackbar v-model="snackbar" :timeout="timeout">
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";
import api from "@/api.config.js";

export default {
  name: "CalificationStudentsTable",
  data: () => ({
    snackbar: false,
    text: "",
    timeout: 3000,
    loaded: false,
    view_loaded: false,

    students: [],
    id_grupo: null,
    cursos: [],
    curso_selected: null,
    notas: [],
    grupo_name: "",
    prueba: 0,
    periodos_academicos: [],
    notas_envio: [],
    notas_recibidas: [],
    notas_data: [],
    students_notas: [],
    arr_status_periodo_academico: [],
    items_docente: [],
    id_grupo_selected: null,
    cursos_profesor: [],
    status_button_save: false,
  }),
  computed: {
    ver_alumno_status_btn() {
      if (this.id_grupo_selected && this.curso_selected) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ejecutar() {
      this.prueba++;
    },
    get_grupos() {
      let items = [];
      let localdata = JSON.parse(localStorage.getItem("token-user"));
      axios
        .get(
          api.API_PATH + "/api/cursos-por-profesor/" + localdata.id + "/",
          this.get_config()
        )
        .then((response) => {
          let aux = response.data;

          this.cursos_profesor = aux;
          aux.forEach((e) => {
            items.push({
              title:
                e.grupo.grado.descripcion +
                " " +
                e.grupo.seccion.descripcion +
                " " +
                e.grupo.nivel.descripcion,
              id_grupo: e.grupo.id,
            });
            this.items_docente = items;
          });
          // console.log(items)
        })
        .catch((e) => {
          console.log(e);
        });
    },
    get_config() {
      let tok = JSON.parse(localStorage.getItem("token-user"));
      let token = tok["token-user"];
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
      };
      return config;
    },
    get_students() {
      this.students = [];
      axios
        .get(
          api.API_PATH +
            "/api/listar-estudiantes-por-grupo/" +
            this.id_grupo_selected.id_grupo +
            "/",
          this.get_config()
        )
        .then((response) => {
          this.grupo_name =
            response.data[0].grupo.grado.descripcion +
            " " +
            response.data[0].grupo.seccion.descripcion +
            " " +
            response.data[0].grupo.nivel.descripcion;
          let aux = response.data;

          aux.forEach((e) => {
            this.students.push({
              id_alumno: e.alumno.id,
              full_name:
                e.alumno.paterno + " " + e.alumno.materno + " " + e.alumno.name,
              idgrupo: e.grupo.id,
              nota: null,
            });
          });
          this.students.sort(function(a, b) {
            if (a.full_name > b.full_name) return 1;
            if (a.full_name < b.full_name) return -1;
            return 0;
          });
          this.get_notas();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    get_cursos() {
      //console.log(this.cursos_profesor)
      let aux = [];
      this.cursos = [];
      if (this.id_grupo_selected) {
        aux = this.cursos_profesor.filter(
          (e) => e.grupo.id == this.id_grupo_selected.id_grupo
        );
        //console.log(aux)
        aux.forEach((e) => {
          e.curso.forEach((ele) => {
            this.cursos.push({
              nombre_curso: ele.nombre_curso,
              id: ele.id,
            });
          });
        });
      }
      //console.log("desde component",this.cursos)
    },
    get_periodo_academico() {
      let anio = 2021;
      axios
        .get(
          api.API_PATH + "/api/get-periodo-academico/" + anio + "/",
          this.get_config()
        )
        .then((r) => {
          this.periodos_academicos = r.data;
          //console.log("periodos",r.data)
          this.periodos_academicos.sort(function(a, b) {
            if (a.id > b.id) return 1;
            if (a.id < b.id) return -1;
            return 0;
          });
          //console.log("periodos ordenados",this.periodos_academicos)
          this.periodos_academicos.forEach((e) => {
            this.arr_status_periodo_academico.push(e.is_active);
          });
          //console.log(this.arr_status_periodo_academico)
        })
        .catch((e) => {
          console.log(e);
        });
    },
    actualizar_tabla() {
      this.get_students();
      this.status_button_save = true;
      this.view_loaded = true;
    },
    get_notas() {
      //let periodo = this.periodos_academicos.find(e=>e.is_active)
      //console.log(periodo.id)
      //console.log(this.curso_selected)
      let datalocal = JSON.parse(localStorage.getItem("token-user"));
      let id_user = datalocal.id;

      axios
        .get(
          api.API_PATH +
            "/api/get-historial-grupo/" +
            this.curso_selected.id +
            "/" +
            id_user +
            "/" +
            this.id_grupo_selected.id_grupo +
            "/",
          this.get_config()
        )
        .then((r) => {
          //console.log("hitorialllll",r.data)
          this.notas_data = r.data;
          this.mezclar_data_notas_alumno();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    mezclar_data_notas_alumno() {
      let aux = [];

      this.students.forEach((e) => {
        let id_nota = this.notas_data.find(
          (element) => element.alumno == e.id_alumno
        );
        // console.log("resultado id_nota",id_nota)
        if (id_nota) {
          //console.log("mostar por cada id que existe de un alumno ")
          //console.log(this.notas_data)
          let id_curso = this.notas_data.find(
            (element) => element.curso == this.curso_selected.id
          );
          if (id_curso) {
            let template_notas = [];
            this.periodos_academicos.forEach((element) => {
              template_notas.push({
                calificacion: null,
                id_historial: null,
                id_periodo: element.id,
                status_nota: null,
              });
            });
            aux.push({
              id_alumno: e.id_alumno,
              full_name: e.full_name,
              idgrupo: e.idgrupo,
              id_curso: id_curso.curso,
              status: "update",
              notas: template_notas,
            });
            //  console.log("mostrar por curso cada nota del alumno")
          }
        } else {
          //let periodo = this.periodos_academicos.find(e=>e.is_active)
          let template_notas = [];
          this.periodos_academicos.forEach((e) => {
            template_notas.push({
              calificacion: null,
              id_historial: null,
              id_periodo: e.id,
              status_nota: null,
            });
          });
          aux.push({
            id_alumno: e.id_alumno,
            full_name: e.full_name,
            idgrupo: e.idgrupo,
            id_curso: this.curso_selected.id,
            status: "create",
            notas: template_notas,
          });
        }
      });
      //console.log(aux)
      //console.log(this.notas_data)
      for (let i = 0; i < aux.length; i++) {
        let notas_alumno_registradas = this.notas_data.filter(
          (e) => aux[i].id_alumno == e.alumno
        );
        //console.log("notas registradas por alumno",notas_alumno_registradas)
        notas_alumno_registradas.forEach((na) => {
          let ind = aux[i].notas.findIndex(
            (e) => e.id_periodo == na.periodo_academico
          );
          //console.log("indice encontrado: "+ind)
          aux[i].notas[ind].calificacion = na.calificacion;
          aux[i].notas[ind].id_periodo = na.periodo_academico;
          aux[i].notas[ind].id_historial = na.id;
        });
      }
      //console.log(aux)
      this.students_notas = aux;
      this.loaded = true;
    },
    post_notas() {
      let datalocal = JSON.parse(localStorage.getItem("token-user"));
      let id_teacher = datalocal.id;
      let notas_update = [];
      let notas_create = [];
      let periodo_activo = this.periodos_academicos.find((e) => e.is_active);

      this.students_notas.forEach((alumno) => {
        alumno.notas.forEach((nota) => {
          if (periodo_activo.id == nota.id_periodo && !nota.id_historial) {
            notas_create.push({
              calificacion: nota.calificacion,
              curso: alumno.id_curso,
              alumno: alumno.id_alumno,
              periodo_academico: nota.id_periodo,
              profesor: id_teacher,
              grupo: alumno.idgrupo,
            });
          } else if (periodo_activo.id == nota.id_periodo) {
            notas_update.push({
              id: nota.id_historial,
              calificacion: nota.calificacion,
              curso: alumno.id_curso,
              alumno: alumno.id_alumno,
              periodo_academico: nota.id_periodo,
              profesor: id_teacher,
              grupo: alumno.idgrupo,
            });
          }
        });
      });

      //console.log("crear",notas_create)
      this.post_notas_array(notas_create);
      this.put_notas_array(notas_update);
      //console.log("actualizar",notas_update)
    },
    post_notas_array(arr) {
      if (arr.length > 0) {
        let aux = arr.every((e) => e.calificacion);
        if (aux) {
          this.status_button_save = false;
          axios
            .post(
              api.API_PATH + "/api/create-nota-array/",
              arr,
              this.get_config()
            )
            .then(() => {
              this.get_notas();
              setTimeout(() => {
                this.status_button_save = true;
              }, 2000);
              this.text = "Datos guardados con éxito";
              this.snackbar = true;
            })
            .catch((err) => {
              this.text = err;
              this.snackbar = true;
            });
        } else {
          this.text = "Completa todas las notas";
          this.snackbar = true;
        }
      }
    },
    put_notas_array(arr) {
      if (arr.length > 0) {
        let aux = arr.every((e) => e.calificacion);
        if (aux) {
          this.status_button_save = false;
          axios
            .put(
              api.API_PATH + "/api/create-nota-array/",
              arr,
              this.get_config()
            )
            .then(() => {
              this.status_button_save = true;
              this.text = "Datos guardados con éxito";
              this.snackbar = true;
            })
            .catch((err) => {
              this.text = err;
              this.snackbar = true;
            });
        } else {
          this.text = "Completa todos las notas";
          this.snackbar = true;
        }
      }
    },
  },
  mounted() {
    this.get_grupos();
    this.get_periodo_academico();
  },
};
</script>
<style>
.input-style {
  width: 80%;
  height: 80%;
  background-color: #ecebeb;
  text-align: center;
  border-radius: 10px;
}
.input-style:focus {
  outline-width: 0px;
  background-color: #fff;
}
</style>
