<template>
    <div>
        <calification-students-table/>
    </div>
</template>
<script>
import CalificationStudentsTable from '../components/CalificationStudentsTable.vue'
export default {
  components: { CalificationStudentsTable },
}
</script>